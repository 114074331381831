@import url('https://rsms.me/inter/inter.css');

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.75 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;

@import 'bootstrap/scss/bootstrap.scss';

* { 
    font-family: 'Inter', sans-serif; 
    letter-spacing: 2px;
    color: #1d1d1f;
}

@supports (font-variation-settings: normal) {
  * { font-family: 'Inter var', sans-serif; }
}

html, body {
    padding: 0px; 
    margin: 0px;
}

h1 {
    font-weight: 900;
    text-transform: uppercase;
    white-space: initial;

    @media screen and (min-width: 1200px){
        font-size: 5vw;
    }

}

h3 {
    font-weight: 300;
    text-transform: uppercase;
}

.bold {
    font-weight: 600;
}

.light {
    font-weight: 200;
}